import { InstanceError } from '@apptypes/instance';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { updatePaymentType } from '@modules/Contract/submodules/Transactions/services';
import { create as filesCreate } from '@modules/PendingWithdrawals/submodules/Files/services';
import { ColumnFiltersState } from '@tanstack/react-table';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';

export const getAll = async (
  page = 1,
  size = 10,
  confirmed: string,
  filterItems: ColumnFiltersState,
) => {
  try {
    const filters = filterItems.map((item) => {
      if (item.id === 'orderBy' && confirmed === 'true') {
        const orderBy = JSON.parse((item?.value as string) || '[]');

        return {
          id: item.id,
          value: JSON.stringify([['updated_at', 'DESC'], ...orderBy]),
        };
      }

      return item;
    });

    const orderBy = filters.find((item) => item.id === 'orderBy');
    if (!orderBy && confirmed === 'true') {
      filters.push({
        id: 'orderBy',
        value: JSON.stringify([['updated_at', 'DESC']]),
      });
    }

    const queryString = generateToQueryString({
      page,
      size,
      filterItems: filters,
      otherParams: `confirmed=${confirmed}`,
    });
    const { data } = await instance.get(`/pending-withdrawals${queryString}`);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Retiradas não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const approveWithdrawalsByID = async (
  withdrawalID: string,
  contractTransitionID: string,
  paymentType: string,
  receiptFile: string,
  receiptName: string,
) => {
  try {
    const { data } = await instance.put(`/pending-withdrawals/${withdrawalID}`, {
      confirmed: true,
      receiptFile,
      receiptName,
    });

    await filesCreate({
      pending_withdrawals_id: withdrawalID,
      file: receiptFile,
      name: receiptName,
    });

    await updatePaymentType(contractTransitionID, paymentType);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível aprovar a retirada',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Retirada não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para aprovar essa transação',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const uploadWithdrawalsByID = async (withdrawalID: string) => {
  try {
    const { data } = await instance.put(`/pending-withdrawals/${withdrawalID}`, {
      confirmed: true,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível aprovar a retirada',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Retirada não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para aprovar essa transação',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const downloadFile = async (transactionID: string, receiptType: string) => {
  try {
    const file = await fetch(
      `${process.env.BASE_URL}/pending-withdrawals/${transactionID}/download`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const contentType = {
      PDF: 'application/pdf',
      PNG: 'image/png',
      JPEG: 'image/jpeg',
    };

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: contentType[receiptType.toUpperCase()] });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (page = 1, size = 1000, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const file = await fetch(
      `${process.env.BASE_URL}/pending-withdrawals/report/export${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
