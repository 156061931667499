import { moneyTransform, transformPercentage } from '@helpers/transforms/money';
import React from 'react';
import { Badge, Card, CardBody } from 'reactstrap';
import { percentageBadge } from './utils';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface CustodyWithdrawProps {
  value: number;
  percentage: number;
  isLoading: boolean;
}

export const CustodyWithdraw = ({ value, percentage, isLoading }: CustodyWithdrawProps) => (
  <Card>
    <CardBody style={{ minHeight: 155 }}>
      <div className="d-flex align-items-center mb-3">
        <div className="avatar-xs me-3">
          {isLoading ? (
            <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
          ) : (
            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
              <i className="bx bx-money" />
            </span>
          )}
        </div>
        <h5 className="font-size-14 mb-0">
          {isLoading ? <Skeleton width={150} /> : 'Retiradas mensais geral'}
        </h5>
      </div>
      <div className="text-muted mt-4">
        {isLoading ? (
          <Skeleton count={2} />
        ) : (
          <>
            <h4>
              {moneyTransform(value)}
              <i className="mdi mdi-chevron-up ms-1 text-success" />
            </h4>
            <div className="d-flex">
              <Badge className={`badge-soft-${percentageBadge(percentage)} font-size-12`}>
                {transformPercentage(percentage)}
              </Badge>
              <span className="ms-2 text-truncate">Do período anterior</span>
            </div>
          </>
        )}
      </div>
    </CardBody>
  </Card>
);
