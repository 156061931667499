import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { IWithRouter } from '@apptypes/WithRouter';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import getChartColorsArray from '@component/common/ChartDynamicColor';
import { CustodyValue } from '../components/CustodyValue';
import { CustodyProjection } from '../components/CustodyProjection';
import { CustodyWithdraw } from '../components/CustodyWithdraw';
import { CustodyWithdrawRealocation } from '../components/CustodyWithdrawRealocation';
import { CustodyContractType } from '../components/CustodyContractType';
import { CustodyContractTypeValue } from '../components/CustodyContractTypeValue';

const Custody = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [isLoading] = useState(false);

  const typesOptions = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: `{a} <br/>{b} : {c} ({d}%)`,
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: ['Retirada recorrentes', 'Retiradas não recorrentes'],
      textStyle: {
        color: ['#8791af'],
      },
    },
    color: getChartColorsArray(
      '["--bs-primary","--bs-danger", "--bs-warning","--bs-info", "--bs-success"]',
    ),
    series: [
      {
        name: 'Modalidade de retiradas',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          { value: 335, name: 'Retirada recorrentes' },
          { value: 310, name: 'Retiradas não recorrentes' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  const valueOptions = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : R$ {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: ['Valor em retirada recorrentes', 'Valor em retiradas não recorrentes'],
      textStyle: {
        color: ['#8791af'],
      },
    },
    color: getChartColorsArray(
      '["--bs-success","--bs-info", "--bs-warning","--bs-info", "--bs-success"]',
    ),
    series: [
      {
        name: 'Valor em modalidade de retiradas',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          { value: 5430.23, name: 'Valor em retirada recorrentes' },
          { value: 1250.54, name: 'Valor em retiradas não recorrentes' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatório de Custódia',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de Custódia',
      }}
    >
      <Row>
        <Col lg="3">
          <CustodyValue value={5231} percentage={0.2} isLoading={isLoading} />
        </Col>
        <Col lg="3">
          <CustodyProjection value={2311} percentage={2} isLoading={isLoading} />
        </Col>
        <Col lg="3">
          <CustodyWithdraw value={5400} percentage={-0.7} isLoading={isLoading} />
        </Col>
        <Col lg="3">
          <CustodyWithdrawRealocation value={2371} percentage={-0.1} isLoading={isLoading} />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <CustodyContractType isLoading={isLoading} options={typesOptions} />
        </Col>
        <Col lg="6">
          <CustodyContractTypeValue isLoading={isLoading} options={valueOptions} />
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Custody);
